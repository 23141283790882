































































import {
  Component,
  Vue, Watch
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import {
  Web
} from '@/store/web'
import _ from 'lodash'
@Component({
  components: {

  },
})
export default class Profile extends Vue {

  profile:any = null
  myagency:any = null
  formPassword:any = {}
  agencies:any = null
  agencyType:any = null
  chooseAgencyType:any = null
  iProfile:any = {}
  formAg:any = null
  response:boolean = false

  async created() {
    this.response = false
    await Web.switchLoad(true)
    await this.loadEnv()
    await this.loadMyAgency()
    await this.loadAgencyType()
    await this.loadProfile()
    await Web.offLoad()
    this.response = true
  }
  async loadAgencyType(){
    let typeAg = await Core.getHttp(`/api/ita/v1/agencytype/`)
    if(this.profile.ext_link.in_up ){
      typeAg.pop();
      this.agencyType = typeAg
    }else{
      this.agencyType = typeAg[2]
    }
  }
  async loadProfile(){
    let profile = await Core.getHttp(`/api/ita/v1/profile/?user=${this.profile.pk}`);
    if(profile.length > 0){
      this.iProfile = profile[0]
    }

  }
  @Watch('chooseAgencyType')
  private async chnageChooseAgencyType(val: number) {
    this.agencies = await Core.getHttp(`/api/ita/v2/agencys/?agency_type=${val}`)
  }
  async loadEnv(){
    this.profile = await User.getUser();
  }

  async loadMyAgency(){
    this.myagency = await Core.getHttp(`/api/ita/v1/agency/${this.profile.ext_link.agency}/`)
  }
  err:any = null
  async changePassword(){
    let check = confirm('คุณแน่ใจใช่ไหมที่จะเปลี่ยนรหัสผ่าน')
    if(check){
      let changePassword = await Core.postHttp(`/accounts/change-password/`,this.formPassword)
      if(changePassword.detail == 'Password changed successfully'){
        alert('แก้ไขรหัสผ่านสำเร็จแล้ว')
        location.reload()
      }else if(changePassword.old_password || changePassword.password || changePassword.password_confirm ){
        this.err = changePassword
      }else{
        alert('ไม่สามรถแก้ไขรหัสผ่านได้ กรุณาตรวสอบข้อมูลให้ถูกต้อง  ')
      }
    }
  }

  async changeAgency(){
    let data = this.iProfile;
    data.agency = this.formAg
    let check= confirm('คุณแน่ใจใช่ไหมที่จะแก้ไขข้อมูลหน่วยงาน')
    if(check){
      let formTmp = {
        "user": this.iProfile.user,
        "agency": this.profile.ext_link.agency,
        "agency_change": this.formAg
      }
      
      let profile = await Core.putHttp(`/api/ita/v1/profile/${data.id}/`,data)
      if(profile.id){
        let log = await Core.postHttp(`/api/ita/v1/agencychange/`,formTmp)
            alert('แก้ไขข้อมูลหน่วยงานของคุณสำเร็จแล้ว')
        location.reload()
      }else{
        alert('ไม่สามารถแก้ไขข้อมูลหน่วยงานได้กรุณา ติดต่อผู้ดูและระบบ')
      }
    }

  }

}
